<template>
    <header class="header" :class="navigationVisible ? 'is-opened' : ''">
        <div class="page-wrap-left">
            <div class="l-header-container">
                <router-link to="/" class="l-header-section-logo-container" v-on:click.native="closeNavigation"><img
                        alt="Jupiter wellness logo" src="@/assets/logo.svg" class="header-section-logo"></router-link>

                <div class="menu-open" @click="toggleNavigation" :class="!navigationVisible ? 'is-closed' : ''">
                    <svg class="icon" width="100%" height="100%" viewBox="0 0 20 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
                        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                        <g id="Menu" transform="matrix(1,0,0,1,-263,-21)">
                            <path d="M264,35C263.448,35 263,34.552 263,34C263,33.448 263.448,33 264,33L282,33C282.552,33 283,33.448 283,34C283,34.552 282.552,35 282,35L264,35ZM264,29C263.448,29 263,28.552 263,28C263,27.448 263.448,27 264,27L282,27C282.552,27 283,27.448 283,28C283,28.552 282.552,29 282,29L264,29ZM264,23C263.448,23 263,22.552 263,22C263,21.448 263.448,21 264,21L282,21C282.552,21 283,21.448 283,22C283,22.552 282.552,23 282,23L264,23Z"
                                style="fill-rule:nonzero;" />
                        </g>
                    </svg> <span>Menu</span>
                </div>

                <div class="menu-close" @click="toggleNavigation" :class="navigationVisible ? 'is-opened' : ''">
                    <svg class="icon" width="100%" height="100%" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
                        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                        <g id="Close" transform="matrix(1,0,0,1,-265,-20)">
                            <path d="M273,26.388L279.033,20.354C280.108,19.28 281.72,20.892 280.646,21.967L273.806,28.806C273.361,29.251 272.639,29.251 272.194,28.806L265.354,21.967C264.28,20.892 265.892,19.28 266.967,20.354L273,26.388ZM273,29.612L266.967,35.646C265.892,36.72 264.28,35.108 265.354,34.033L272.194,27.194C272.639,26.749 273.361,26.749 273.806,27.194L280.646,34.033C281.72,35.108 280.108,36.72 279.033,35.646L273,29.612Z"
                                style="fill-rule:nonzero;" />
                        </g>
                    </svg>
                    <span>Zavřít</span>
                </div>
            </div>

            <div class="l-header-navigation-container">
                <nav class="header-navigation">
                    <ul class="l-header-navigation-list">
                        <li class="header-navigation__item">
                            <a href="https://jupiterwellness.cz/" class="link">Úvod</a>
                        </li>
                        <li class="header-navigation__item">
                            <a href="https://jupiterwellness.cz/sluzby-a-vybaveni" class="link">Služby
                                a vybavení</a>
                        </li>
                        <li class="header-navigation__item">
                            <a href="https://jupiterwellness.cz/galerie" class="link">Galerie</a>
                        </li>
                        <li class="header-navigation__item">
                            <a href="https://jupiterwellness.cz/cenik" class="link">Ceník</a>
                        </li>
                        <li class="header-navigation__item">
                            <a href="https://jupiterwellness.cz/kontakt" class="link">Kontakt</a>
                        </li>
                          <li class="header-navigation__item">
                            <a href="https://www.e-chalupy.cz/cesky_raj/ubytovani-pencin-posed-horizont-pronajem-12054.php" target="_blank" class="link">Posed Horizont <small>Novinka</small></a>
                        </li>
                        <li class="header-navigation__item">
                            <a href="https://jupiterwellness.cz/rezervace" class="button">Rezervace a obsazenost</a>
                        </li>
                    </ul>
                </nav>


                <div class="l-header-navigation-centering-container">
                    <section class="header-contacts">
                        <ul class="l-header-contacts-list">
                            <li class="header-contacts__item"><a href="#" class="link">
                                    <svg class="icon" width="100%" height="100%" viewBox="0 0 14 14" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                                        <g id="Phone" transform="matrix(1,0,0,1,-1,-8)">
                                            <path d="M13.72,18.165L13.721,18.149C13.729,17.825 13.492,17.546 13.176,17.502C12.514,17.414 11.864,17.253 11.238,17.019C11.004,16.932 10.74,16.988 10.565,17.16L9.753,17.971C9.549,18.175 9.235,18.217 8.985,18.075C7.283,17.109 5.875,15.703 4.907,14.005C4.765,13.755 4.807,13.441 5.011,13.237L5.821,12.429C5.996,12.252 6.052,11.989 5.964,11.754C5.731,11.13 5.569,10.481 5.482,9.826C5.437,9.509 5.164,9.274 4.836,9.277L2.918,9.277C2.739,9.277 2.567,9.353 2.446,9.485C2.325,9.617 2.266,9.794 2.281,9.963C2.48,11.837 3.119,13.637 4.148,15.224C5.08,16.687 6.323,17.928 7.794,18.861C9.371,19.882 11.166,20.52 13.023,20.721C13.203,20.737 13.38,20.677 13.513,20.556C13.646,20.434 13.721,20.263 13.72,20.08L13.72,18.165ZM15,20.078C15.002,20.617 14.776,21.132 14.378,21.497C13.98,21.861 13.446,22.042 12.897,21.992C10.829,21.768 8.843,21.062 7.103,19.936C5.478,18.905 4.101,17.53 3.072,15.913C1.936,14.163 1.229,12.171 1.008,10.088C0.959,9.552 1.139,9.021 1.502,8.624C1.865,8.227 2.378,8.001 2.918,8L4.83,8C5.793,7.991 6.614,8.695 6.75,9.653C6.824,10.218 6.963,10.773 7.162,11.306C7.426,12.006 7.257,12.795 6.728,13.33L6.269,13.787C7.021,14.965 8.022,15.965 9.202,16.715L9.663,16.255C10.196,15.729 10.986,15.561 11.686,15.823C12.221,16.023 12.777,16.161 13.349,16.236C14.311,16.372 15.019,17.203 15,18.172L15,20.078Z"
                                                style="fill-rule:nonzero;" />
                                    </g>
                                    </svg>
                                    <span class="link-text">+420 733 120 972</span></a>
                            </li>
                            <li class="header-contacts__item"><a href="mailto:info@jupiterwellness.cz" class="link">
                                    <svg class="icon" width="100%" height="100%" viewBox="0 0 15 12" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                                        <g id="Mail--" transform="matrix(1,0,0,1,7.38982e-11,-37)">
                                            <path d="M2,38.333C1.635,38.333 1.333,38.635 1.333,39L1.333,47C1.333,47.365 1.635,47.667 2,47.667L12.667,47.667C13.032,47.667 13.333,47.365 13.333,47L13.333,39C13.333,38.635 13.032,38.333 12.667,38.333L2,38.333ZM2,37L12.667,37C13.768,37 14.667,37.898 14.667,39L14.667,47C14.667,48.102 13.768,49 12.667,49L2,49C0.899,49 0,48.102 0,47L0,39C0,37.898 0.899,37 2,37ZM7.333,42.853L13.618,38.454C13.919,38.243 14.335,38.316 14.546,38.618C14.757,38.919 14.684,39.335 14.382,39.546L7.716,44.213C7.486,44.374 7.181,44.374 6.951,44.213L0.284,39.546C-0.017,39.335 -0.091,38.919 0.121,38.618C0.332,38.316 0.747,38.243 1.049,38.454L7.333,42.853Z"
                                                style="fill-rule:nonzero;" />
                                        </g>
                                    </svg>
                                    <span class="link-text">info@jupiterwellness.cz</span></a>
                            </li>
                            <li class="header-contacts__item mobile"><a href="https://www.facebook.com/jupiterwellness/" class="link">
                                    <svg class="icon" width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
                                        <title>Shape</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="1.-Hlavní-stránka" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="jupiter-/-homepage-m-&gt;-main-menu" transform="translate(-24.000000, -579.000000)"
                                                fill="#000000" fill-rule="nonzero">
                                                <g id="Group-8" transform="translate(20.000000, 515.000000)">
                                                    <path d="M5.90909091,69.0909091 L5.90909091,67.8181818 C5.90909091,65.7094582 7.61854914,64 9.72727273,64 L11.6363636,64 C11.9878176,64 12.2727273,64.2849097 12.2727273,64.6363636 L12.2727273,67.1818182 C12.2727273,67.5332721 11.9878176,67.8181818 11.6363636,67.8181818 L9.72727273,67.8181818 L9.72727273,69.0909091 L11.6363636,69.0909091 C12.0503635,69.0909091 12.3541368,69.4799748 12.253727,69.8816136 L11.6173634,72.4270681 C11.5465414,72.7103562 11.2920067,72.9090909 11,72.9090909 L9.72727273,72.9090909 L9.72727273,77.3636364 C9.72727273,77.7150903 9.44236302,78 9.09090909,78 L6.54545455,78 C6.19400061,78 5.90909091,77.7150903 5.90909091,77.3636364 L5.90909091,72.9090909 L4.63636364,72.9090909 C4.2849097,72.9090909 4,72.6241812 4,72.2727273 L4,69.7272727 C4,69.3758188 4.2849097,69.0909091 4.63636364,69.0909091 L5.90909091,69.0909091 Z M11,65.2727273 L9.72727273,65.2727273 C8.321457,65.2727273 7.18181818,66.4123661 7.18181818,67.8181818 L7.18181818,69.7272727 C7.18181818,70.0787267 6.89690848,70.3636364 6.54545455,70.3636364 L5.27272727,70.3636364 L5.27272727,71.6363636 L6.54545455,71.6363636 C6.89690848,71.6363636 7.18181818,71.9212733 7.18181818,72.2727273 L7.18181818,76.7272727 L8.45454545,76.7272727 L8.45454545,72.2727273 C8.45454545,71.9212733 8.73945516,71.6363636 9.09090909,71.6363636 L10.5031423,71.6363636 L10.8213241,70.3636364 L9.09090909,70.3636364 C8.73945516,70.3636364 8.45454545,70.0787267 8.45454545,69.7272727 L8.45454545,67.8181818 C8.45454545,67.115274 9.02436486,66.5454545 9.72727273,66.5454545 L11,66.5454545 L11,65.2727273 Z"
                                                        id="Shape"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span class="link-text">/jupiterwellness</span></a></li>
                            <li class="header-contacts__item tablet">
                                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fjupiterwellness%2F&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&hide_cta=true&appId" width="100%" height="130" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                            </li>
                        </ul>
                    </section>

                    <section class="header-copyright">
                        <p class="desc">Jupiter Wellness &copy; 2020<br><a href="https://www.e-chalupy.cz/jizerske_hory/ubytovani-alsovice-chata-jupiter-wellness-pronajmuti-2472.php" target="_blank" rel="noopener" class="link">e-chalupy</a> • <a href="https://maps.app.goo.gl/2PqxD73PQqfYvkvBA" target="_blank" rel="noopener" class="link">Masáže Pěnčín</a> • Vytvořil: <a href="https://michalbounla.cz" target="_blank" rel="noopener" class="link">Michal Bounla</a></p> 
                    </section>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'TheHeader',
    props: {},
    data: function () {
        2011
        return {
            navigationVisible: false,
        }
    },
    computed: {

    },
    methods: {
        toggleNavigation() {
            this.navigationVisible = !this.navigationVisible;
        },
        closeNavigation() {
            this.navigationVisible = false;
        }
    }
}
</script>

<style lang="scss">
  @import '@/scss/main.scss';

  .header {
      position: fixed;
      top: 0;
      z-index: $z-index-1;
      height: rem(64);
      display: flex;
      align-content: center;
      background: $light;
      width: 100%;
      box-shadow: 0 0 16px 0 #78746933;
      overflow: hidden;

      @include breakpoint(desktop) {
          width: 280px;
          display: flex;
          order: 1;
          height: 100vh;
          overflow-y: auto;
      }

      @include breakpoint(desktop-wide) {
          width: 320px;
      }

      .l-header-container {
          display: flex;
          justify-content: space-between;
          height: rem(64);

          @include breakpoint(desktop) {
              height: rem(140);
          }
      }

      &.is-opened {
          height: auto;
          z-index: $z-index-1;
          position: fixed;

          .l-header-navigation-container {

              @include breakpoint(desktop) {
                  height: calc(100vh - #{rem(140)});
                  display: flex;
              }
          }

          @include breakpoint(desktop) {
              height: inherit;
              z-index: inherit;
              position: inherit;
          }
      }

      .l-header-section-logo-container {
          margin-right: auto;
          display: flex;

          img {
              width: auto;
              height: rem(44);
              display: flex;
              align-self: center;

              @include breakpoint(desktop) {
                  height: rem(92);
              }
          }
      }

      .menu-open {
          font-size: rem(16);
          color: $dark;
          margin-left: auto;
          display: none;
          cursor: pointer;

          &.is-closed {
              display: flex;

              @include breakpoint(desktop) {
                  display: none;
              }
          }

          .icon,
          span {
              display: flex;
              align-self: center;
          }

          .icon {
              margin-right: rem(12);
              width: 1.25rem;
              height: 1.25rem;
          }

          span {
              user-select: none;
          }
      }

      .menu-close {
          font-size: rem(16);
          color: $dark;
          margin-left: auto;
          display: none;
          cursor: pointer;

          &.is-opened {
              display: flex;

              @include breakpoint(desktop) {
                  display: none;
              }
          }

          .icon,
          span {
              display: flex;
              align-self: center;
          }

          .icon {
              margin-right: rem(12);
              width: 1.25rem;
              height: 1.25rem;
          }

          span {
              user-select: none;
          }
      }

      .l-header-navigation-container {
          width: 100%;
          padding: rem(20) 0;
          display: none;

          height: calc(100% - #{rem(140)});
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @include breakpoint(tablet) {
              display: flex;
              flex-direction: row;
          }

          @include breakpoint(desktop) {
              flex-direction: column;
          }

          .desc {
              font-size: rem(13);
          }
      }

      .l-header-navigation-list {}

      .header-navigation__item {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: normal;
          color: #212123;
          text-transform: uppercase;
          display: block;
          min-width: rem(240);
          width: auto;
          margin: 0 0 rem(20) 0;
          @include ul-reset;

          @include breakpoint(desktop) {
              min-width: auto;
            margin: 0 0 rem(32) 0;
          }

          .link {
              display: inline-block;
              border-bottom: 2px solid transparent;
              transition: border-color .2s ease-in-out;

              &:hover {
                  border-color: #e3ac47;
                  text-decoration: none;

              }
          }

          small {
              border-radius: rem(10);
              color: #ffffff;
              font-size: rem(12);
              line-height: rem(16);
              background-color: #e3ac47;
              padding: rem(2) rem(6);
              text-transform: uppercase;
              position: relative;
              bottom: rem(2);
          }

          .button {
              .icon {
                  fill: #fff;
                  position: absolute;
                  left: rem(12);
              }
          }
      }

      .header-contacts__item {
          margin: 0 0 rem(16) 0;

          .icon {
              margin-right: rem(12);
          }

          &.mobile {
              @include breakpoint(tablet) {
                  display: none;
              }
          }

          &.tablet {
              display: none;

              @include breakpoint(tablet) {
                  display: block;
              }
          }
      }

      .l-header-contacts-list {
          @include ul-reset;

          .link {
              font-size: rem(13);
              display: flex;
              align-items: center;
          }
      }

      .button {
          background-color: #212123;
          color: #fff;
          text-align: center;
          width: 100%;
          max-width: rem(240);
          box-shadow: none;
          margin: 0;

          &:hover {
              background-color: #48484A;
          }
      }
  }
</style>
